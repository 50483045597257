import React, { useEffect, useMemo, useState } from 'react';
import useStore from 'globalstate';
import { getLoginURL, GET_MEETUP_DETAIL, MY_SUBSCRIPTION_PATH, TECHX_PATH, COMMUNITY_PATH } from 'constants/app';
import { EMeetupTypes, TMeetup } from '../../types';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import UpdatedCard from 'fragments/UpdatedCard/UpdatedCard';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';
import { captureException } from '@sentry/minimal';
import {
  FaAward,
  FaChalkboardTeacher,
  FaCheck,
  FaCode,
  FaLaptop,
  FaLaptopCode,
  FaLongArrowAltLeft,
  FaMoneyBillAlt,
  FaUserFriends,
  FaWrench,
} from 'react-icons/fa';
import Schedule from './schedule';
import fetch from 'utils/fetch';
import { getCurrencySymbol } from 'utils/currency';
import Error from 'components/Error';
import { reqRegisterUser, requestDeRegistration, saveRegistrationDetails } from 'fragments/DefaultCard';
import toaster from 'utils/toast';
import { enrol } from 'components/GHF/Modal';
import Modal from 'fragments/Modal';
import DynamicForm from 'components/DynamicForm';
import { getDynamicFormFields } from 'utils/form-fields';
import DeregisterModal from 'components/AllEvents/Deregister';
import { openURL } from 'utils/url';
import Payment from 'components/Payment';
import GlobalSlot from 'components/Subscription/GlobalSlot/GlobalSlot';
import TTabs from './tabs';
import FreeSubscriptionModal from 'components/AllEvents/FreeSubscription';
import Spinner from 'fragments/Spinner';
import ScheduleTimeline from './scheduleTimeline';

export default function MeetupDetail() {
  const { meetupName } = useParams() as any;
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [meetup, setMeetup] = useState<TMeetup>();
  const [isRegModalOpen, setIsRegModalOpen] = useState(false);
  const [isFreeSubscriptionModalOpen, setIsFreeSubscriptionModalOpen] = useState(false);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const [isGlobalSlotModalOpen, setIsGlobalSlotModalOpen] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(!!meetup?.registered_on || false);
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [State] = useStore();
  const history = useHistory();
  const { isAuthenticated, currentPlan } = State;
  const [currentPlanState, setCurrentPlanState] = useState(currentPlan);

  const location = useLocation() as any;
  const pageName = useMemo(() => location?.pathname?.slice(1)?.split('/')[0], [location]);

  const callback = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  const closeRegModal = () => {
    setIsRegModalOpen(false);
  };

  function openDeregisterModal() {
    setIsDeregisterModalOpen(true);
  }
  function closeIsFreeSubscriptionModal() {
    setIsFreeSubscriptionModalOpen(false);
  }

  function openGlobalSlotModal() {
    setIsGlobalSlotModalOpen(true);
  }
  function closeIsGlobalSlotModal() {
    setIsGlobalSlotModalOpen(false);
  }
  const openMeetupLink = () => {
    if (meetup?.page_url) openURL(meetup?.page_url);
  };

  async function saveRegistration(data: { [key: string]: string }): Promise<boolean> {
    if (!meetup) return false;
    return new Promise(async (resolve, reject) => {
      let respJSON;
      try {
        const response = await reqRegisterUser(data, meetup.id);
        respJSON = await response.json();
        if (
          response.status == 403 &&
          respJSON.error.startsWith('You do not have the required permissions to access this event')
        ) {
          const data = { registered_on: moment().format('DD-MM-YYYY HH:mm:ss') };
          localStorage.setItem('regEvntNonCnfrmd', JSON.stringify({ data: data, event: { id: meetup.id } }));
          const isEnrolled: boolean | string = await enrol();
          if (isEnrolled == false) {
            toaster.error('Unable to register you to GHF!');
            captureException(`Unable to Register  for event ${meetup.meta.display_name}`);
            return false;
          }
        }
        if (response.status === 403 && respJSON.error) toaster.error(respJSON.error);
        if (response.status !== 200) return reject(respJSON.error);
        toaster.success('You are successfully registered!');
        setIsRegistered(true);
        resolve(respJSON);
      } catch (e) {
        captureException(e);
      }
    });
  }

  async function openRegModal() {
    if (isAuthenticated)
      if (
        State?.currentPlan?.plan_display_name?.toLowerCase() === meetup?.plan_name?.toLowerCase() &&
        !meetup?.has_access
      ) {
        setIsFreeSubscriptionModalOpen(true);
        return;
      }
    if (isAuthenticated)
      if (meetup?.meta.require_registration)
        if (meetup.meta.registration_fields) setIsRegModalOpen(true);
        else
          saveRegistration({ registered_on: moment().format('DD-MM-YYYY HH:mm:ss') })
            .then()
            .catch(err => {
              toaster.error(err);
            });
      else
        saveRegistration({ registered_on: moment().format('DD-MM-YYYY HH:mm:ss') })
          .then()
          .catch(err => {
            toaster.error(err);
          });
    else window.location.href = getLoginURL(window.location.href);
  }

  const getSub = () => {
    if (!meetup) return;
    if (meetup.plan_name && !meetup.registered_on && !meetup.has_access)
      return (
        <button
          type="button"
          className="rounded-lg p-4"
          onClick={() => {
            history.push(MY_SUBSCRIPTION_PATH);
          }}
        >
          {'Checkout Plans'}
        </button>
      );
  };
  const getActions = () => {
    if (!meetup) return;
    if (meetup.type === EMeetupTypes.event && !meetup.meta.standalone_registrable && !!meetup.registered_on)
      return (
        <button type="button" className="mr-3 rounded-lg pr-3 ml-3 pl-3 p-4" onClick={openDeregisterModal}>
          Deregister
        </button>
      );
    else if (meetup.type === EMeetupTypes.event && !meetup.meta.standalone_registrable && !meetup.registered_on) return;

    if (meetup.meta.require_registration && !isRegistered)
      if (meetup.alacarte_rate !== null && !meetup.has_access)
        return (
          <button
            type="button"
            className="rounded-lg p-4"
            onClick={meetup.meta.registration_fields ? openRegModal : openGlobalSlotModal}
          >
            {isAuthenticated ? 'Buy Stand-alone Program' : 'Login to Register'}
          </button>
        );
      else
        return (
          <button type="button" className="rounded-lg p-4" onClick={openRegModal}>
            {isAuthenticated ? 'Register' : 'Login to Register'}
          </button>
        );
    else if (isRegistered && meetup.page_url)
      return (
        <div className="flex">
          <a type="button" className="rounded-lg p-4" href={meetup.page_url} target="_blank">
            Go to Course
          </a>

          {/* <button type="button" className="rounded-lg p-4 space-x-3" onClick={openMeetupLink}>
            Join
          </button> */}

          <button type="button" className="mr-3 rounded-lg pr-3 ml-3 pl-3" onClick={openDeregisterModal}>
            Deregister
          </button>
        </div>
      );
    else
      return (
        <React.Fragment>
          <button type="button" className="mr-3 pr-3 pl-3 cursor-not-allowed" disabled>
            Awaiting Meetup Link
          </button>
          <button type="button" className="mr-3 pr-3 pl-3" onClick={openDeregisterModal}>
            Deregister
          </button>
        </React.Fragment>
      );
  };

  const tabContent = () => {
    return (
      <div className="  rounded-lg dark:bg-gray-800 " id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <div className="grid lg:grid-cols-10 grid-cols-1 md:gap-y-4">
          <div className="col-span-7">
            <div className="border-solid border-2 mb-4 ">
              <div className="p-8">
                <h3 className="font-switzer text-text-dark">What you will Learn</h3> <br />
                <div className="grid md:grid-cols-2 grid-cols-1 gap-y-10 ">
                  {meetup &&
                    meetup?.meta.learn_topics &&
                    meetup?.meta.learn_topics.map((val: string) => {
                      return (
                        <div className="grid md:grid-cols-8">
                          <FaCheck className="text-3xl"></FaCheck>
                          <p className="col-span-7">{val}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="border-solid border-2 mb-4">
              <div className="p-8">
                <h3 className="font-switzer text-text-dark">Description</h3> <br />
                <Markdown className="font-switzer text-text-dark">{meetup?.description || ''}</Markdown>
              </div>
            </div>

            <div className="border-solid border-2 ">
              <div className="p-8">
                <h3 className="font-switzer text-text-dark">About {meetup?.meta.speaker_name?.toUpperCase()}</h3> <br />
                <Markdown className="font-switzer text-text-dark">{meetup?.meta.speaker_bio || ''}</Markdown>
              </div>
            </div>
          </div>
          <div className="col-span-3 font-switzer text-text-dark flex flex-col gap-y-10 p-8 border-solid border-2 lg:ml-3 mt-4 md:mt-0">
            {meetup?.meta.domain != 'community' && (
              <div className=" flex gap-x-4 ">
                <FaAward className="text-4xl" />
                <span className="text-xl font-light "> Get Certificate</span>
              </div>
            )}

            <div className=" flex gap-x-4 ">
              <FaLaptop className="text-4xl" />
              <span className="text-xl font-light ">100% Online</span>
            </div>

            <div className=" flex gap-x-4 ">
              <FaUserFriends className="text-4xl" />
              <span className="text-xl font-light "> Cohorts</span>
            </div>

            <div className=" flex gap-x-4 ">
              <FaChalkboardTeacher className="text-4xl" />
              <span className="text-xl font-light "> Best Faculty</span>
            </div>

            <div className=" flex gap-x-4 ">
              <FaCode className="text-4xl" />
              <span className="text-xl font-light "> Hands On Sessions</span>
            </div>

            <div className=" flex gap-x-4 ">
              <FaWrench className="text-4xl" />
              <span className="text-xl font-light "> Real World Application</span>
            </div>

            <div className=" flex gap-x-4 ">
              <FaLaptopCode className="text-4xl" />
              <span className="text-xl font-light ">Jobs</span>
            </div>

            {meetup?.meta.domain != 'community' && (
              <div className=" flex gap-x-4 ">
                <FaMoneyBillAlt className="text-4xl" />
                <span className="text-xl font-light ">Flexi Payment </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const tab2Content = () => {
    return (
      <div
        className="font-switzer text-text-dark rounded-lg dark:bg-gray-800"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        {meetup?.schedule ? (
          <ScheduleTimeline schedules={meetup?.schedule}></ScheduleTimeline>
        ) : (
          // <Schedule
          //   schedules={[...meetup?.schedule, ...meetup?.schedule, ...meetup?.schedule, ...meetup?.schedule]}
          // ></Schedule>
          'Please wait for schedule , we will release it soon'
        )}
      </div>
    );
  };

  const tab3Content = () => {
    return (
      <div
        className=" rounded-lg dark:bg-gray-800   "
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
        key={meetup?.name}
      >
        <div className="p-4 grid md:grid-cols-2 grid-cols-1 gap-x-4 gap-y-4  h-full overflow-y-scroll">
          {meetup?.included_meetups
            ? meetup?.included_meetups?.map((v: TMeetup, i: number) => {
                return (
                  <div>
                    <UpdatedCard
                      data={v}
                      key={'meetup-' + v.id}
                      onStateChange={() => {
                        return;
                      }}
                    />
                  </div>
                );
              })
            : 'No Courses In This program ! Please Contact us this might be a mistake.'}
        </div>
      </div>
    );
  };
  let tabs = [
    {
      label: 'About', // Tab Title - String
      Component: tabContent, // Tab Body - JSX.Element
    },
    {
      label: 'Schedule',
      Component: tab2Content,
    },
  ];
  if (meetup?.type.toLowerCase() === 'workshop')
    tabs.push({
      label: 'Courses',
      Component: tab3Content,
    });

  //fetch the meetup By Name
  const getMeetups = async (name: string) => {
    setIsLoading(true);
    try {
      const resp = await fetch(`${GET_MEETUP_DETAIL}/${name}`, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw Error(respJSON.error);
      const detmeetup = await respJSON.data;
      setMeetup(detmeetup);
      setIsRegistered(!!detmeetup.registered_on);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (meetupName) {
      getMeetups(meetupName);
      tabs = [...tabs];
    }
  }, [meetupName]);

  useEffect(() => {
    const cp = localStorage.getItem('cp');
    if (cp && !currentPlan) {
      const cpo = JSON.parse(cp);
      setCurrentPlanState(cpo);
    } else if (currentPlan) {
      localStorage.setItem('cp', JSON.stringify(currentPlan));
      setCurrentPlanState(currentPlan);
    }
  }, [currentPlan]);

  if (loading) return <Spinner space show></Spinner>;
  if (error) return <Error preset="nothing"></Error>;
  if (!meetup) return <Error preset="nothing"></Error>;
  return (
    <React.Fragment>
      <h3 className="font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center">
        <Link
          to={pageName === 'techx' ? TECHX_PATH : COMMUNITY_PATH}
          className="font-switzer text-sm 3xl:text-base text-text-blue font-normal"
        >
          {pageName === 'techx' ? 'TechX' : 'Community'}
        </Link>{' '}
        &nbsp;/&nbsp;
        <span className="font-switzer text-sm 3xl:text-base text-text-blue font-normal cursor-default">
          {meetup?.name}
        </span>
      </h3>
      <div className="h-screen font-switzer">
        <section className=" bg-gradient-to-r  from-green-500 to-indigo-400 relative ">
          <div onClick={() => history.goBack()} className="cursor-pointer absolute top-16 md:top-20 left-16">
            {' '}
            <FaLongArrowAltLeft className=" text-white  md:text-4xl font-thin text-3xl " />{' '}
          </div>

          <div className="text-white space-y-2 grid md:grid-cols-10 grid-cols-1 ">
            <div className="col-span-8 pt-24 md:pt-20 2xl:pt-24 3xl:pt-32 md:pl-40 pl-20">
              <div className="lg:text-3xl xl:text-4xl text-2xl font-bold wrap md:w-2/3 w-full">
                {meetup?.meta.display_name}
              </div>
              <div className="text-lg font-light mt-8">
                {moment(meetup?.scheduled_datetime).format('DD-MMM-YY h:mm A')}
              </div>

              <div className="text-lg font-bold">
                <span className="font-light">Designed By</span> {meetup?.meta.speaker_name?.toUpperCase()}
              </div>
              <div className="text-lg font-bold ">
                {meetup?.meta?.standalone_registrable || meetup.type == EMeetupTypes.workshop
                  ? meetup?.is_free
                    ? 'FREE'
                    : meetup?.alacarte_rate
                    ? getCurrencySymbol(meetup?.currency) + ' ' + meetup?.alacarte_rate
                    : ''
                  : ''}
                {(meetup.type == EMeetupTypes.workshop || meetup?.meta?.standalone_registrable) && !meetup?.is_free && (
                  <Link to={MY_SUBSCRIPTION_PATH}>
                    <span className="text-xs">
                      {!meetup?.is_free && meetup.plan_name
                        ? ` ${
                            meetup?.alacarte_rate
                              ? '( As a Standalone Program or Check our Subscription Plans )'
                              : ' Check our Subscription Plans  '
                          } `
                        : ''}
                    </span>
                  </Link>
                )}
              </div>

              {meetup?.meta.available_slots && meetup?.meta.total_slots ? (
                <div className="text-sm  ">
                  <span className="font-bold"> {meetup?.meta.available_slots}</span> of {meetup?.meta.total_slots} slots
                  remaining
                </div>
              ) : (
                ''
              )}
              <div className="flex flex-row gap-x-4 lg:my-6 md:my-4 my-2">
                <div className="flex flex-row gap-x-4">
                  {getSub()}
                  {getActions()}
                </div>
              </div>
            </div>
            <div className="col-span-2  relative hidden md:block">
              <div className="absolute ">
                <img src={meetup?.meta.hero_image}></img>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col">
          {meetup && (
            <div className="z-10">
              <TTabs color="indigo" tabContent={tabs}></TTabs>
            </div>
          )}
        </section>
        <section></section>

        <DeregisterModal
          isOpen={isDeregisterModalOpen}
          onDeregistration={() => {
            setIsRegistered(false);
            setIsDeregisterModalOpen(false);
          }}
          eventId={meetup.id}
          displayName={meetup.meta.display_name || meetup.name}
          closeModal={() => {
            setIsDeregisterModalOpen(false);
          }}
        ></DeregisterModal>

        <Modal isOpen={isRegModalOpen} close={closeRegModal} size="lg" bgColor="bg-white">
          <DynamicForm
            closeModal={closeRegModal}
            onUpdate={
              meetup.is_free || meetup.has_access
                ? saveRegistration
                : () => {
                    setIsGlobalSlotModalOpen(true);
                  }
            }
            formFields={getDynamicFormFields(meetup.meta.registration_fields)}
            eventName={meetup.name}
          ></DynamicForm>
        </Modal>

        <Modal isOpen={isGlobalSlotModalOpen} close={closeIsGlobalSlotModal} size="lg" bgColor="bg-white">
          {currentPlan?.is_global_slot_selected ? (
            <React.Fragment>
              <Payment
                productId={meetup?.product_id?.toString()}
                paymentId={meetup?.payment_id}
                slotId=""
                meetupId={meetup.id}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GlobalSlot
                product={{
                  product_id: meetup?.product_id,
                  payment_id: meetup.payment_id,
                  name: meetup.name,
                  billing_frequency: '',
                  displayname: meetup.name,
                  features: '',
                  price: meetup.alacarte_rate,
                  currency: meetup.currency,
                  discountedprice: null,
                  description: meetup.description,
                  meetupId: meetup.id,
                }}
                close={closeIsGlobalSlotModal}
              />
            </React.Fragment>
          )}
        </Modal>
        <FreeSubscriptionModal isOpen={isFreeSubscriptionModalOpen} closeModal={closeIsFreeSubscriptionModal} />
      </div>
    </React.Fragment>
  );
}
